import { Component, Input, OnInit } from '@angular/core';

//import { competences } from '../model/competences';
//import { Comptence } from '../model/comptence';

import { TEXTS } from '../data/texts';
import { EXTRA_DATA } from '../data/extra-data';
import { COMPETENCES } from '../data/competences';

@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent implements OnInit {

    texts = TEXTS;
    extraData = EXTRA_DATA;
    competences = COMPETENCES;
        @Input("language") language: number;

    constructor() { }

    ngOnInit() {
    }

}

