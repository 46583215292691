import { Component, OnInit } from '@angular/core';

import { EXTRA_DATA } from '../data/extra-data';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

    extraData = EXTRA_DATA;

    constructor() { }

    ngOnInit() {
    }

}
