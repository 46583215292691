import { Component, Input, OnInit } from '@angular/core';

import { TEXTS } from '../data/texts';
import { EXTRA_DATA } from '../data/extra-data';

@Component({
  selector: 'app-air',
  templateUrl: './air.component.html',
  styleUrls: ['./air.component.scss']
})
export class AirComponent implements OnInit {

    texts = TEXTS;
    extraData = EXTRA_DATA;
    @Input("language") language: number;

    constructor() { }

    ngOnInit() {
    }
}

