import { Component, Input, OnInit } from '@angular/core';

import { TEXTS } from '../data/texts';
import { EXTRA_DATA } from '../data/extra-data';

@Component({
  selector: 'app-whoswho',
  templateUrl: './whoswho.component.html',
  styleUrls: ['./whoswho.component.scss']
})
export class WhoswhoComponent implements OnInit {

    texts = TEXTS;
    extraData = EXTRA_DATA;
    @Input("language") language: number;

    constructor() { }

  ngOnInit() {
  }

}
