import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

//import { LanguageService } from '../language.service';

import { Language } from '../model/language';
import { PageLink } from '../model/page-link';

import { TEXTS } from '../data/texts';
import { EXTRA_DATA } from '../data/extra-data';
import { LANGUAGES } from '../data/languages';
import { PAGE_LINKS } from '../data/page-links';

@Component({
    selector: 'app-top',
    templateUrl: './top.component.html',
    styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

    texts = TEXTS;
    extraData = EXTRA_DATA;
    languages = LANGUAGES;
    pageLinks = PAGE_LINKS;

    @Input("language") language: number;
    @Output() languageChange = new EventEmitter();

    constructor(
        //public languageService: LanguageService
    ) { }

    ngOnInit() {
        if(navigator.language.toLowerCase().indexOf("fr") > -1) {
            //this.languageService.setLanguage(1);
            this.languageChange.emit(1);
        } else {
            //this.languageService.setLanguage(0);
            this.languageChange.emit(0);
        }
    }

    changeLanguage(language: Language): void {
//        this.languageService.setLanguage(language.id);

        this.languageChange.emit(language.id);
    }

}
