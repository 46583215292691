export const TEXTS = [
    {
        "logoText": "ADEA Consultancy",
        "drives": "\"Mijn drijfveren zijn ICT, Java, qualiteit en creativiteit. Mijn expertise zit in applicatieve frontend- en backend-ontwikkeling aangevuld met een stevige dosis internetkennis waarbij security nooit uit het oog wordt verloren.\"",
        "competencesTitle": "Competenties",
        "whoswhoTitle": "Wie",
        "whoswhoIntroduction": "Heeft meer dan 20 jaar professionele ervaring als softwareontwikkelaar in zowel kleine maar vooral grote ondernemingen in voornamelijk de bancaire sector. Heeft als Java backend- en als Java frontend-ontwikkelaar tal van projecten tot een goed einde weten brengen, zowel in team als individueel.",
        "contactTitle": "Contact",
        "name": "Naam",
        "email": "Email",
        "subject": "Onderwerp",
        "message": "Bericht",
        "send": "Verzend",
        "emailNotification0": "Gelieve de velden ",
        "emailNotification1": "Gelieve het veld ",
        "emailNotification2": " in te vullen.",
        "emailNotification3": "Gelieve het email-adres te corrigeren.",
        "emailNotification4": "Het bericht werd verstuurd.",
        "emailNotification5": "Er was een probleem bij het versturen van het bericht.",
        "emailNotification6": " en ",
    },
    {
        "logoText": "ADEA Consultancy",
        "drives": "\"My drives are ICT, Java, quality and creativity. My expertise is applicative frontend and backend developement complemented with a strong dose of internet knowledge where security is never lost out of sight.\"",
        "competencesTitle": "Competences",
        "whoswhoTitle": "Who",
        "whoswhoIntroduction": "Has more than 20 years of professional experience as software developer in small but mostly big enterprises mainly in the banking sector. As a Java backend and Java frontend developer has managed to bring many projects to a successful ending, both in team as well as individually.",
        "contactTitle": "Contact",
        "name": "Name",
        "email": "E-mail",
        "subject": "Subject",
        "message": "Message",
        "send": "Contact",
        "emailNotification0": "Please fill in fields ",
        "emailNotification1": "Please fill in field ",
        "emailNotification2": ".",
        "emailNotification3": "Please correct the email address.",
        "emailNotification4": "The message has ben sent.",
        "emailNotification5": "A problem occured while sending the message.",
        "emailNotification6": " and ",
    },
];


