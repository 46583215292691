import { PageLink } from "../model/page-link";

export const PAGE_LINKS: PageLink[] = [
    {
        id: 0,
        label: [
            "Competenties",
            "Competences",
        ],
        link: "#competences",
    },
    {
        id: 1,
        label: [
            "Wie",
            "Who",
        ],
        link: "#whoswho",
    },
    {
        id: 2,
        label: [
            "Contact",
            "Contact",
        ],
        link: "#contact",
    },
];

