import { Injectable } from '@angular/core';

import { MailMessage } from './model/mail-message';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

//let currentUrl = window.location.href;
//console.log(currentUrl.substr(0, currentUrl.indexOf(":")));
//console.log(currentUrl.substr(0, currentUrl.indexOf(":")));

//const endpoint = "http://192.168.1.3/n/mail.php";
//const endpoint = "http://192.168.1.3/n/api/mail/";
const endpoint = "://www.adea.be/api/mail/";
//const endpoint = "http://localhost:4200/";

const httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/json"
    })
};

@Injectable({
    providedIn: 'root'
})
export class MailService {

    constructor(
        private http: HttpClient
    ) { }

    getProtocol() {
        let currentUrl = window.location.href;
        return currentUrl.substr(0, currentUrl.indexOf(":"));
    }

    sendMessage(mailMessage: MailMessage): Observable<any> {
//console.log(window.location.protocol+endpoint);
        return this.http.post<MailMessage>(this.getProtocol()+endpoint, mailMessage, httpOptions).pipe(
            catchError(this.handleError<any>("woops")),
        );
    }

    private handleError<T> (operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            console.log(`${operation} failed: $error{error.message}`);
            return of(result as T);
        };
    }
}

