export const EXTRA_DATA = {
    "logoSource": "assets/img/logo.png",
    "airSource": "assets/img/sky.jpg",
    "overlaySource": [
        "assets/img/overlay_nl.png",
        "assets/img/overlay_en.png"
    ],
    "name": "Samuel",
    "picturesSource": "assets/img/maroonPixel.png",
    "telefoon": "0123456789",
    "copyright": "Copyright \u00A9 Adea",
    showExternalContact: false,
    showMe: false,
};

