import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

/*    constructor(
        private languageService: LanguageService
    ) { }*/

    public language = 0;
    title = 'adeaaa';
    //static currentLanguage = 0;

    changeLanguage(language: number) {
        this.language = language;
    }
}
