import { Competence } from "../model/competence";

export const COMPETENCES: Competence[] = [
    {
        wordCloudSource: "assets/img/wordCloudBackEnd.png",
            competenceText: [
                "Backend-ontwikkeling met een uitstekende kennis van Java, Eclipse, Jboss, Spring, Hibernate en Maven.",
                "Backend developement with excellent knowledge of Java, Eclipse, Jboss, Spring, Hibernate and Maven." 
            ]
    },
    {
        wordCloudSource: "assets/img/wordCloudFrontEnd.png",
            competenceText: [
                "Frontend-ontwikkeling met een uitstekende kennis van Javascript, Angular, HTML5, SCSS (CSS), REST en SSL.",
                "Frontend developement with excellent knowledge of Javascript, Angular, HTML5, SCSS (CSS), REST and SSL." 
            ]
    }

];


