import { ContactPoint } from "../model/contact-point";

export const CONTACT_POINTS: ContactPoint[] = [
    {
        text: [
            "Contacteer me via email",
            "Contact me by e-mail",
        ],
        link: "mailto:to@do.be",
        label: "To do",
        icon: "assets/img/mail.png",
    },
    {
        text: [
            "Ga naar mijn LinkedIn",
            "Go to my LinkedIn",
        ],
        link: "https://www.adea.be/api/linkedin/",
        label: "LinkedIn",
        icon: "assets/img/linkedin.png",
    },

];

