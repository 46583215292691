import { Component, Input, OnInit } from '@angular/core';

import { MailService } from '../mail.service';

import { MailMessage } from '../model/mail-message';

import { TEXTS } from '../data/texts';
import { EXTRA_DATA } from '../data/extra-data';
import { CONTACT_POINTS } from '../data/contact-points';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactFormMessageStyle = "contactFormMessageNothing";
    contactFormMessageContent = "";
    texts = TEXTS;
    extraData = EXTRA_DATA;
    contactPoints = CONTACT_POINTS;
    mailMessage: MailMessage = {
        name: "",
        subject: "",
        emailAddress: "",
        message: "",
    }
    @Input("language") language: number;

    constructor(
        private mailService: MailService,
    ) { }

    ngOnInit() {
        // In case the browser is IE 11, hide the contact form as it doesn't work out of the box.
        // IE versions lower than 11 are blocked by the server based detection script and a rudimentary page is shown.
        /*if(navigator.userAgent.toLowerCase().indexOf("trident") > -1) {
            document.getElementById("contactForm1").style.display = "none";
        }*/
    }

    sendMessage(): void {
        let currentLanguage = this.language;
        let isError: boolean = false;
        let emptyFields:string[] = [];
        if(this.mailMessage.name.length < 1) {
            isError = true;
            emptyFields[emptyFields.length] = this.texts[currentLanguage]["name"];
        }
        if(this.mailMessage.emailAddress.length < 1) {
            isError = true;
            emptyFields[emptyFields.length] = this.texts[currentLanguage]["email"];
        }
        if(this.mailMessage.subject.length < 1) {
            isError = true;
            emptyFields[emptyFields.length] = this.texts[currentLanguage]["subject"];
        }
        if(this.mailMessage.message.length < 1) {
            isError = true;
            emptyFields[emptyFields.length] = this.texts[currentLanguage]["message"];
        }
        if(isError) {
            let message = "";
            let counter = 0;
            for(let emptyField of emptyFields) {
                message += "'"+emptyField+"'";
                if(counter < emptyFields.length - 1) {
                    if(counter < emptyFields.length - 2) {
                        message += ", ";
                    } else {
                        message += this.texts[currentLanguage]["emailNotification6"];
                    }
                }
                counter++;
            }
            if(emptyFields.length == 1) {
                this.displayErrorMessage(this.texts[currentLanguage]["emailNotification1"]+message+this.texts[currentLanguage]["emailNotification2"]);
            } else {
                    this.displayErrorMessage(this.texts[currentLanguage]["emailNotification0"]+message+this.texts[currentLanguage]["emailNotification2"]);
            }
            return;
        }

        if(this.mailMessage.emailAddress.indexOf("@") > this.mailMessage.emailAddress.lastIndexOf(".") ||
            this.mailMessage.emailAddress.indexOf(".") < 0 ||
            (this.mailMessage.emailAddress.substring(0, this.mailMessage.emailAddress.indexOf("@"))).length < 1 ||
            (this.mailMessage.emailAddress.substring(this.mailMessage.emailAddress.indexOf("@")+1, this.mailMessage.emailAddress.indexOf("."))).length < 1 ||
            (this.mailMessage.emailAddress.substring(this.mailMessage.emailAddress.indexOf(".")+1)).length < 2
        ) {
            isError = true;
            this.displayErrorMessage(this.texts[currentLanguage]["emailNotification3"]);
            return;
        }

        if(this.mailMessage.emailAddress.length < 1) {
            isError = true;
            emptyFields[emptyFields.length] = this.texts[currentLanguage]["email"];
        }

        let resultStatus = "0";
        this.mailService.sendMessage(this.mailMessage).subscribe(
            response => {
                //console.log("+0+"+response.status)
                if(response.status == "0") {
                    this.contactFormMessageContent = this.texts[currentLanguage]["emailNotification5"];
                    this.contactFormMessageStyle = "contactFormMessageError";
                } else {
                    this.contactFormMessageContent = this.texts[currentLanguage]["emailNotification4"];
                    this.contactFormMessageStyle = "contactFormMessageSuccess";
                    this.mailMessage.subject = "";
                    this.mailMessage.message = "";
                }
            }
        );
    }

    displayErrorMessage(message: string): void {
        this.contactFormMessageContent = message;
        this.contactFormMessageStyle = "contactFormMessageError";
    }
}
